<template>
  <v-card class="mx-auto rounded-0" elevation="0">
    <v-card-text>
      <v-row class="mb-1">
        <v-col cols="12" sm="12">
          <v-card class="rounded-0 elevation-0">
            <v-text-field
              class="form-text search-cls"
              dense
              hide-details
              outlined
              placeholder="検索"
              v-model="search"
              append-icon="mdi-magnify"
              @input="searchData"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="header" dense>
        <v-col cols="2" class="text-center text-small">
          物件ID
        </v-col>
        <v-col cols="4" class="text-center text-small">
          物件名
        </v-col>
        <v-col cols="6" class="text-center text-small">
          顧客名
        </v-col>
      </v-row>
      <v-row dense v-for="site in sites" :key="site.id" @click="goToDetailSite(site)">
        <v-col cols="2" class="text-center text-small">
          {{site.site_id}}
        </v-col>
        <v-col cols="4" class="text-center text-small">
          {{site.name}}
        </v-col>
        <v-col cols="6" class="text-center text-small">
          {{site.customer ? site.customer.name : '-'}}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from 'debounce'
import { mapGetters } from "vuex";
export default {
  name: "CustomerList",
  data() {
    return {
      loading: true,
      search: "",
      page: 1,
      paginate: 30,
      sites: [],
      headers: [
        {
          text: '物件ID',
          align: 'center',
          value: 'site_id',
          sortable: false
        },
        {
          text: '物件名',
          align: 'center',
          value: 'name',
          sortable: false
        },
        {
          text: '顧客名',
          align: 'center',
          value: 'customer_name',
          sortable: false
        },
      ]
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi(reset = false) {
      let params = {
        paginate: this.paginate,
        page: this.page,
        search: this.search
      };

      await this.$store.dispatch("SITE_GET_ALL", params).then(() => {
        let site = this.getSites
        if (reset) {
          this.sites = site
        } else {
          this.sites.push(...site)
        }
      });
    },
    goToDetailSite(site) {
      this.$router.push({
        name: "customer-basic",
        params: {
          customer_id: site.customer.id,
          site_id: site.id
        }
      });
    },
    searchData: debounce(function() {
      this.page = 1
      this.getDataFromApi(true);
    }, 500),
    updateScroll() {
      if (this.page < this.getSitePagination.total_pages) {
        if((window.innerHeight + window.scrollY) >= document.body.offsetHeight){
          this.page++
          this.getDataFromApi()
        }
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapGetters(["getSitePagination", "getSites"]),
  },
};
</script>

<style lang="scss" src="./customer.scss" scoped></style>
