<template>
  <div>
    <!-- -----------------------    header -------------------------- -->
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="rounded-0 elevation-0">
          <v-card-text>
            <v-row class="ma-0 bg-blk">
              <v-col cols="6" sm="6" class="pb-0">
                <v-select
                  :items="years"
                  v-model="selectedYear"
                  solo
                  dense
                  @change="getOffDays"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    {{ `${item}年` }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ `${item}年` }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" class="pb-0">
                <v-select
                  :items="months"
                  v-model="selectedMonth"
                  solo
                  dense
                  hide-details
                  @change="getOffDays"
                >
                  <template v-slot:selection="{ item }">
                    {{ `${item}月` }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ `${item}月` }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="12"> </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="text-center">
            <span class="information">
              {{ attendanceSummaryText }}
            </span>
          </v-card-text>
          <v-simple-table dense class="mb-6 pt-4 custom-table">
              <template>
                <thead>
                  <tr>
                    <th></th>
                    <th class="text-center">昼</th>
                    <th class="text-center">夜</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="date in datesWithOffDays" :key="date.id">
                    <td class="text-center" :class="getDateColor(date.date)">
                      {{ date.date | formatDate() }}（{{
                        getDayFormat(date.date)
                      }}）
                    </td>
                    <td class="text-center">
                      <OffDayEvent
                        :date="date.date"
                        :off-day="date.day"
                        type="day"
                        @toggleOffDayStatus="toggleOffDayStatus"
                      />
                    </td>
                    <td class="text-center">
                      <OffDayEvent
                        :date="date.date"
                        :off-day="date.night"
                        type="night"
                        @toggleOffDayStatus="toggleOffDayStatus"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters, mapActions } from "vuex";
import OffDayEvent from "@/components/layout/offDays/list/OffDayEvent";

export default {
  components: { OffDayEvent },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  filters: {
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    }
  },
  data() {
    return {
      dates: [],
      datesModified: [],
      years: [],
      year: dayjs(),
      month: dayjs().date(20),
      pastMonth: dayjs()
        .subtract(1, "month")
        .date(21),
      selectedMonth: dayjs().format("M"),
      selectedYear: dayjs().format("YYYY"),
      currentMonth: null,
      currentYear: null,
      params: {
        from: null,
        to: null
      },
      formData: {
        date: null,
        user_id: null,
        status: null,
        off_range: null
      }
    };
  },

  computed: {
    ...mapGetters(["allOffDays", "profile"]),
    yearFormatted() {
      return this.year.format("YYYY年");
    },
    monthFormatted() {
      return this.month.format("MM月");
    },
    pastMonthFormatted() {
      return this.month.subtract(1, "month").format("MM月");
    },
    attendanceSummaryText() {
      return `${this.yearFormatted}${this.pastMonthFormatted}21日～${this.monthFormatted}20日休暇サマリー`;
    },
    datesWithOffDays() {
      if (!this.allOffDays) return;
      let dates = [];
      this.dates.forEach(date => {
        let offDays = this.allOffDays?.filter(offDay => offDay.date === date);
        let dayOffday =
          offDays?.find(offDay => offDay.day_status !== null) || null;
        let nightOffday =
          offDays?.find(offDay => offDay.night_status !== null) || null;

        dates.push({
          date: date,
          day: dayOffday,
          night: nightOffday
        });
      });

      return dates;
    },
    months() {
      let arr = [];
      for (let i = 1; i <= 12; i++) {
        arr.push(`${i}`);
      }
      return arr;
    }
  },
  methods: {
    ...mapActions([
      "ALL_OFF_DAYS",
      "CHANGE_OFF_DAYS_STATUS",
      "ALL_OFF_DAYS_BY_MONTH",
      "OFF_DAY_CREATE",
      "OFF_DAY_UPDATE",
      "OFF_DAY_REMOVE"
    ]),
    getDayFormat(date) {
      return dayjs(date).format(`dd`);
    },
    async toggleOffDayStatus({ status, id, date, type }) {
      await this.OFF_DAY_UPDATE({ status, id, date, type });
      await this.getOffDays();
    },

    getDateColor(date) {
      date = dayjs(date);
      let today = dayjs();
      if (date.weekday() === 0 || date.weekday() === 6) {
        return "red--text";
      }

      if (date.isBefore(today)) {
        return "grey--text";
      }

      return "";
    },

    getDates(from, to) {
      let dates = [];
      while (from.isSameOrBefore(to)) {
        dates.push(from.format("YYYY-MM-DD"));
        from = from.add(1, "d");
      }
      return dates;
    },

    getOffDays() {
      this.month = dayjs(`${this.selectedYear}-${this.selectedMonth}-20`);
      this.pastMonth = this.month.subtract(1, "month").date(21);
      this.year = this.month;
      this.params.to = this.month.format("YYYY-MM-DD");
      this.params.from = this.pastMonth.format("YYYY-MM-DD");
      let to = this.month;
      let from = this.pastMonth;

      this.dates = this.getDates(from, to);
      this.ALL_OFF_DAYS_BY_MONTH(this.params);
    },

    getTheYearList() {
      let max = dayjs().add(5, "year");
      let min = max.subtract(10, "year");

      while (min.year() !== max.year()) {
        this.years.push(min.format("YYYY"));

        min = min.add(1, "y");
      }
    }
  },
  created() {
    let today = dayjs();
    if (today.date() >= 21) {
      this.month = this.month.add(1, "month").date(20);
      this.pastMonth = this.month.subtract(1, "month").date(21);
    }
    this.getOffDays();
    this.getTheYearList();
  }
};
</script>
<style scoped>
.bg-blk {
  background: #f9f9f9;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}
.bg-blk >>> .v-input__slot {
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.vcard-cls {
  padding: 8px;
}
.information {
  text-align: center;
  padding: 5px;
  border: 1px solid #333333;
  color: #333333;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 14px;
  max-width: 320px;
  width: 100%;
  display: inline-block;
}
.information {
  text-align: center;
  padding: 5px;
  border: 1px solid #333333;
  color: #333333;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 14px;
  max-width: 307px;
  width: 100%;
  display: inline-block;
}

button.information {
  min-width: 100% !important;
  padding: 0px !important;
  background: #fff !important;
  box-shadow: none;
}

.custom-table thead th {
  border-bottom: 1px solid #333333 !important;
}
.custom-table tbody td {
  border: none !important;
  width: 33%;
  padding: 0 !important;
}
.custom-table tbody tr:hover {
  background: transparent !important;
}
.custom-table thead th:nth-child(2),
.custom-table tbody td:nth-child(2) {
  background: #fffbef;
  width: 35%;
  padding: 0 !important;
}
.custom-table thead th:nth-child(3),
.custom-table tbody td:nth-child(3) {
  background: #f2f2f2;
  width: 35%;
  padding: 0 !important;
}
.custom-table thead th:nth-child(4),
.custom-table tbody td:nth-child(4) {
  background: #f8f9ff;
}
</style>
