<template>
  <CustomerList />
</template>

<script>
import CustomerList from "@/components/layout/customer/CustomerList";
export default {
  name: "Customers",
  components: { CustomerList }
};
</script>

<style scoped></style>
