<template>
  <div class="mx-auto">
    <OffDaysList />
  </div>
</template>

<script>
import OffDaysList from "@/components/offDays/OffDaysList";
export default {
  name: "offDays",
  components: { OffDaysList }
};
</script>

<style scoped></style>
