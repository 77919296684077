<template>
  <div v-if="!loading">
    <OffDaysListSubHeader :loading="loading" />
  </div>
</template>

<script>
import OffDaysListSubHeader from "@/components/layout/offDays/list/OffDaysListSubHeader";
export default {
  name: "OffdayList",
  components: {
    OffDaysListSubHeader
  },
  created() {
    this.loading = false;
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {}
};
</script>

<style scoped></style>
